// 2.1.0

import 'nodelist-foreach-polyfill';
import 'core-js/features/promise';

/**
 * Dynamically load JS components
 * Optionally observe areas for structural HTML changes to reload components
 */
export default class ComponentLoader {
    /**
     * @param {Object} options
     */
    constructor(options = {}) {
        const defaultOptions = {
            componentSelector: '[data-component]',
            componentDatasetName: 'component',
            observerSelector: '[data-component-observe]',
        };
        this.options = {...defaultOptions, ...options};
    }

    /**
     * Load all JS components within an element
     * Start change-observers where wanted to reload components if needed
     *
     * @param {HTMLElement} parentElement
     */
    loadComponentsWithinElement(parentElement) {
        parentElement.querySelectorAll(this.options.componentSelector).forEach(el => this.loadComponentForElement(el));
        parentElement.querySelectorAll(this.options.observerSelector).forEach(el => this.observeElement(el));
    }

    /**
     * Load JS component dynamically
     *
     * @param {HTMLElement} el
     * @param {String|null} component
     *
     * @returns {Promise}
     */
    loadComponentForElement(el, component) {
        if (!component) {
            component = el.dataset[this.options.componentDatasetName];
        }

        return this.importComponent(component)
            .then(component => component.default(el));
    }

    /**
     * Dynamically import component
     * Note: Import path must be hardcoded for now, doesn't work with a variable
     *
     * @param {String} name
     * @return {Promise}
     */
    importComponent(name) {
        return import(`./../components/${name}.js`);
    }

    /**
     * Observe element for structural changes in HTML so we can reload components if needed
     *
     * @param {Node} element
     */
    observeElement(element) {
        this.getObserver().observe(element, {childList: true});
    }

    /**
     * @return {MutationObserver}
     */
    getObserver() {
        if (typeof this.observer !== 'MutationObserver') {
            this.observer = new MutationObserver(mutationsList => {
                mutationsList.forEach((mutation) => {
                    if (mutation.addedNodes.length > 0) {
                        this.loadComponentsWithinElement(mutation.target);
                    }
                })
            });
        }

        return this.observer;
    }
}
